import { lazy } from "react"

const routes = [
  {
    path: "/",
    component: lazy(() => import("../pages/Dashboard")),
    meta: {
      title: "Dashboard",
      icon: "DashboardIcon",
      permission: "staff",
    },
  },
  {
    path: "/map",
    noSub: true,
    component: lazy(() => import("../pages/LiveMap")),
    meta: {
      title: "Live Map",
      icon: "LiveMapIcon",
      permission: "staff",
    },
    children: [
      {
        path: "/map/all",
        component: lazy(() => import("../pages/LiveMap/Data")),
        meta: {
          permission: "staff",
        },
      },
    ],
  },
  {
    path: "/stations",
    component: lazy(() => import("../pages/Stations")),
    noSub: true,
    meta: {
      title: "Stations",
      icon: "Stations",
      permission: "staff",
    },
    children: [
      {
        path: "/station/:id",
        component: lazy(() => import("../pages/Stations/Edit")),
        meta: {
          permission: "staff",
        },
      },
      {
        path: "/stations/:id",
        component: lazy(() => import("../pages/Stations/Location")),
        meta: {
          permission: "staff",
        },
        children: [
          {
            path: "/stations/:id/:slug",
            component: lazy(() => import("../pages/Stations/Location/Station")),
            meta: {
              permission: "staff",
            },
          },
          {
            path: "/station/box/:id",
            component: lazy(() => import("../pages/Stations/Location/Edit")),
            meta: {
              permission: "staff",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/partners",
    component: lazy(() => import("../pages/Partners")),
    meta: {
      title: "Partners",
      icon: "PartnersIcon",
      permission: "staff",
    },
  },
  {
    path: "/locations",
    component: lazy(() => import("../pages/Locations")),
    meta: {
      title: "Locations",
      icon: "LocationIcon",
      permission: "staff",
    },
    noSub: true,
    children: [
      {
        path: "/location/:id",
        component: lazy(() => import("../pages/Locations/Edit")),
      },
      {
        path: "/locations/:id",
        component: lazy(() => import("../pages/Locations/Detail")),
      },
    ],
  },
  {
    path: "/users",
    noSub: true,
    component: lazy(() => import("../pages/Users")),
    meta: {
      title: "Users",
      icon: "UsersIcon",
      permission: "staff",
    },
    children: [
      {
        path: "/users/:id",
        component: lazy(() => import("../pages/Users/Detail")),
        meta: {
          permission: "staff",
        },
      },
    ],
  },
  {
    path: "/payments",
    noSub: true,
    component: lazy(() => import("../pages/Payments")),
    meta: {
      title: "Payments",
      icon: "PaymentsIcon",
      permission: "staff",
    },
    children: [
      {
        path: "/payments/:id",
        component: lazy(() => import("../pages/Payments/Detail")),
        meta: {
          permission: "staff",
        },
      },
    ],
  },
  {
    path: "/vehicles",
    component: lazy(() => import("../pages/Vehicles")),
    noSub: true,
    meta: {
      title: "Vehicles",
      icon: "VehiclesIcon",
      permission: "staff",
    },
    children: [
      {
        path: "/vehicles/:id/",
        component: lazy(() => import("../pages/Vehicles/Models")),
        meta: {
          permission: "staff",
        },
        children: [
          {
            path: "/vehicles/:id/:slug",
            component: lazy(() => import("../pages/Vehicles/Models/Model")),
            meta: {
              permission: "staff",
            },
            children: [
              {
                path: "/vehicles/:id/:slug/:_id",
                component: lazy(() =>
                  import("../pages/Vehicles/Models/Model/Detail")
                ),
                meta: {
                  permission: "staff",
                },
              },
              {
                path: "/vehicle/:id/:slug/edit",
                component: lazy(() =>
                  import("../pages/Vehicles/Models/Model/Edit")
                ),
                meta: {
                  permission: "staff",
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "/sos",
    component: lazy(() => import("../pages/SOS/ServiceType")),
    meta: {
      title: "SOS",
      icon: "ExclamationCircleOutlined",
      permission: "staff",
    },
    children: [
      {
        path: "/sos/service-type",
        component: lazy(() => import("../pages/SOS/ServiceType")),
        meta: { title: "Service type" },
      },
      {
        path: "/sos/service-tarif",
        component: lazy(() => import("../pages/SOS/ServiceTarif")),
        meta: { title: "Service tarif" },
      },
      {
        path: "/sos/couriers",
        component: lazy(() => import("../pages/SOS/Courier")),
        meta: { title: "Couriers" },
      },
    ],
  },
  {
    path: "/retail-service",
    component: lazy(() => import("../pages/Dashboard")),
    meta: {
      title: "Retail & Service",
      icon: "RetailServiceIcon",
      permission: "staff",
    },
  },
  {
    path: "/documentation",
    component: lazy(() => import("../pages/Dashboard")),
    meta: {
      title: "Documentation",
      icon: "DocumentationIcon",
      permission: "staff",
    },
  },
  {
    path: "/Moderators",
    component: lazy(() => import("../pages/Moderators")),
    meta: {
      title: "Moderators",
      icon: "ModeratorsIcon",
      permission: "staff",
    },
  },
  {
    path: "/settings",
    // noSub: true,
    component: lazy(() => import("../pages/Settings/Reasons")),
    meta: {
      title: "Settings",
      icon: "SettingOutlined",
      permission: "staff",
    },
    children: [
      {
        path: "/settings/category",
        component: lazy(() => import("../pages/Settings/Category")),
        meta: {
          title: "Category",
          permission: "staff",
        },
        children: [
          {
            path: "/settings/category/:id",
            component: lazy(() => import("../pages/Settings/Category")),
            meta: {
              permission: "staff",
            },
          },
        ],
      },
      {
        path: "/settings/subscription",
        component: lazy(() => import("../pages/Settings/Subscription")),
        meta: {
          title: "Subscription",
          permission: "staff",
        },
      },
      {
        path: "/settings/reasons",
        component: lazy(() => import("../pages/Settings/Reasons")),
        meta: {
          title: "Reasons",
          icon: "",
          permission: "staff",
        },
      },
      {
        path: "/settings/facilities",
        component: lazy(() => import("../pages/Settings/Facilities")),
        meta: {
          title: "Facilities",
          icon: "",
          permission: "staff",
        },
      },
      {
        path: "/settings/colors",
        component: lazy(() => import("../pages/Settings/Colors")),
        meta: {
          title: "Colors",
          icon: "",
          permission: "staff",
        },
      },
      {
        path: "/settings/brands",
        component: lazy(() => import("../pages/Settings/Brands")),
        meta: {
          title: "Brands",
          icon: "",
          permission: "staff",
        },
      },
      {
        path: "/settings/cities",
        component: lazy(() => import("../pages/Settings/Cities")),
        meta: {
          title: "Cities",
          icon: "",
          permission: "staff",
        },
        children: [
          {
            path: "/settings/cities/:id",
            component: lazy(() => import("../pages/Settings/Cities")),
            meta: {
              title: "Cities",
              icon: "",
              permission: "staff",
            },
          },
        ],
      },
    ],
  },
]

export default routes
