export const formatValue = (value = 0) =>
  value?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")

export function formatPhoneNumber(phoneNumber1) {
  if (!phoneNumber1) {
    return null
  }
  // Raqam faqat raqamlardan iborat ekanligini tekshirish
  let phoneNumber = phoneNumber1
  if (!/^\d{12}$/.test(phoneNumber)) {
    phoneNumber = "998" + phoneNumber1
  }

  // Raqamni formatlash
  let countryCode = phoneNumber.slice(0, 3)
  let operatorCode = phoneNumber.slice(3, 5)
  let numberPart1 = phoneNumber.slice(5, 8)
  let numberPart2 = phoneNumber.slice(8, 10)
  let numberPart3 = phoneNumber.slice(10, 12)

  return `+${countryCode} (${operatorCode}) ${numberPart1}-${numberPart2}-${numberPart3}`
}
