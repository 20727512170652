import {
  SettingOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import { DashboardIcon } from "./icons/DashboardIcon"
import { LiveMapIcon } from "./icons/LiveMapIcon"
import { StationsIcon } from "./icons/StationsIcon"
import { PartnersIcon } from "./icons/PartnersIcon"
import { UsersIcon } from "./icons/UsersIcon"
import { PaymentsIcon } from "./icons/PaymentsIcon"
import { VehiclesIcon } from "./icons/VehiclesIcon"
import { RetailServiceIcon } from "./icons/RetailServiceIcon"
import { DocumentationIcon } from "./icons/DocumentationIcon"
import { ModeratorsIcon } from "./icons/ModeratorsIcon"
import { LocationIcon } from "./icons/LocationIcon"

function IconFinder(icon) {
  switch (icon) {
    case "DashboardIcon":
      return <DashboardIcon />
    case "SettingOutlined":
      return <SettingOutlined />
    case "LiveMapIcon":
      return <LiveMapIcon />
    case "Stations":
      return <StationsIcon />
    case "PartnersIcon":
      return <PartnersIcon />
    case "UsersIcon":
      return <UsersIcon />
    case "PaymentsIcon":
      return <PaymentsIcon />
    case "VehiclesIcon":
      return <VehiclesIcon />
    case "RetailServiceIcon":
      return <RetailServiceIcon />
    case "DocumentationIcon":
      return <DocumentationIcon />
    case "ModeratorsIcon":
      return <ModeratorsIcon />
    case "EnvironmentOutlined":
      return <EnvironmentOutlined />
    case "ExclamationCircleOutlined":
      return <ExclamationCircleOutlined />
    case "LocationIcon":
      return <LocationIcon />
    default:
      break
  }
}
export default IconFinder
