import React from "react"
import { BrowserRouter, Route } from "react-router-dom"
import Routes from "./routes/routes"
import Login from "./pages/Login"
import { ConfigProvider } from "antd"

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            multipleItemBg: "#E3EAFD",
          },
          Switch: {
            colorPrimary: "#26BD6C",
            colorPrimaryHover: "#26BD6C",
          },
          Input: {
            boxShadow: "",
          },
        },

        token: {
          colorPrimary: "#14151A",
          colorText: "#14151A",
          borderRadius: 12,
          fontSize: 14,
          colorBorder: "#DEE0E3",
          colorBorderSecondary: "#E9EAEC",
          colorBgContainer: "#fff",
          colorPrimaryActive: "#fff",
          controlItemBgActive: "rgba(10,15,41,0.04)",
        },
      }}
    >
      <BrowserRouter>
        <Route path="/login" exact component={Login} />
        <Routes />
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
