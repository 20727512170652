export const DashboardIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.99984 1.6665C14.5998 1.6665 18.3332 5.39984 18.3332 9.99984C18.3332 14.5998 14.5998 18.3332 9.99984 18.3332C5.39984 18.3332 1.6665 14.5998 1.6665 9.99984C1.6665 5.39984 5.39984 1.6665 9.99984 1.6665ZM9.99984 16.6665C13.6832 16.6665 16.6665 13.6832 16.6665 9.99984C16.6665 6.3165 13.6832 3.33317 9.99984 3.33317C6.3165 3.33317 3.33317 6.3165 3.33317 9.99984C3.33317 13.6832 6.3165 16.6665 9.99984 16.6665ZM12.9465 5.87484L14.1248 7.05317L9.99984 11.1782L8.8215 9.99984L12.9465 5.87484Z"
        fill="#0F1324"
        fillOpacity="0.6"
      />
    </svg>
  )
}
