export const DocumentationIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M2.50002 6.6665L7.50252 1.6665H16.665C17.125 1.6665 17.5 2.04567 17.5 2.49317V17.5065C17.4998 17.7258 17.4125 17.9361 17.2574 18.0911C17.1022 18.2461 16.8918 18.3332 16.6725 18.3332H3.32752C3.21808 18.3324 3.10987 18.3101 3.00906 18.2675C2.90825 18.2249 2.81681 18.1629 2.73997 18.085C2.66313 18.007 2.60239 17.9148 2.56122 17.8134C2.52005 17.712 2.49925 17.6034 2.50002 17.494V6.6665ZM8.33335 3.33317V7.49984H4.16669V16.6665H15.8334V3.33317H8.33335Z"
        fill="#0F1324"
        fillOpacity="0.6"
      />
    </svg>
  )
}
