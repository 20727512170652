export const RetailServiceIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M2.6342 3.72406C3.60092 2.75717 4.89754 2.19163 6.26387 2.14094C7.6302 2.09024 8.96516 2.55813 10.0009 3.45073C11.0357 2.55962 12.3689 2.09225 13.7335 2.14219C15.0982 2.19214 16.3937 2.7557 17.3606 3.72006C18.3275 4.68443 18.8944 5.97841 18.948 7.34297C19.0015 8.70752 18.5376 10.0419 17.6492 11.0791L11.1784 17.5707C10.8811 17.8681 10.4824 18.0421 10.0621 18.0579C9.64192 18.0737 9.23128 17.93 8.91254 17.6557L8.82087 17.5716L2.35087 11.0791C1.46294 10.0428 0.998852 8.70965 1.05138 7.34604C1.10391 5.98244 1.66919 4.68891 2.6342 3.72406ZM3.81254 4.90239C3.13107 5.58405 2.73825 6.50193 2.71559 7.46553C2.69294 8.42914 3.04219 9.36447 3.69087 10.0774L3.81254 10.2057L10 16.3932L14.4192 11.9732L11.4734 9.02739L10.59 9.91073C10.3579 10.1429 10.0823 10.3272 9.77904 10.4529C9.47573 10.5786 9.15063 10.6434 8.82229 10.6434C8.15919 10.6436 7.52319 10.3803 7.0542 9.91156C6.58521 9.44279 6.32165 8.80691 6.32149 8.14382C6.32133 7.48072 6.5846 6.84472 7.05337 6.37573L8.80504 4.62323C8.10793 4.06687 7.23538 3.77713 6.34394 3.806C5.4525 3.83486 4.60053 4.18044 3.94087 4.78073L3.81254 4.90239ZM10.8842 7.25906C11.0405 7.10283 11.2524 7.01507 11.4734 7.01507C11.6943 7.01507 11.9063 7.10283 12.0625 7.25906L15.5975 10.7941L16.1875 10.2057C16.8804 9.51334 17.2749 8.57732 17.2866 7.59786C17.2982 6.6184 16.9262 5.67324 16.2501 4.96452C15.5739 4.2558 14.6473 3.83978 13.6683 3.80544C12.6894 3.77109 11.7359 4.12114 11.0117 4.78073L10.8842 4.90239L8.23254 7.55406C8.08809 7.6984 8.00168 7.89076 7.9897 8.09461C7.97772 8.29846 8.041 8.49962 8.16754 8.65989L8.23254 8.73239C8.37688 8.87683 8.56924 8.96325 8.77309 8.97523C8.97694 8.98721 9.1781 8.92393 9.33837 8.79739L9.41087 8.73239L10.8842 7.25906Z"
        fill="#0F1324"
        fillOpacity="0.6"
      />
    </svg>
  )
}
