export const UsersIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M1.6665 18.3335C1.6665 16.5654 2.36888 14.8697 3.61913 13.6195C4.86937 12.3692 6.56506 11.6668 8.33317 11.6668C10.1013 11.6668 11.797 12.3692 13.0472 13.6195C14.2975 14.8697 14.9998 16.5654 14.9998 18.3335H13.3332C13.3332 17.0074 12.8064 15.7356 11.8687 14.798C10.931 13.8603 9.65925 13.3335 8.33317 13.3335C7.00709 13.3335 5.73532 13.8603 4.79764 14.798C3.85995 15.7356 3.33317 17.0074 3.33317 18.3335H1.6665ZM8.33317 10.8335C5.57067 10.8335 3.33317 8.596 3.33317 5.8335C3.33317 3.071 5.57067 0.833496 8.33317 0.833496C11.0957 0.833496 13.3332 3.071 13.3332 5.8335C13.3332 8.596 11.0957 10.8335 8.33317 10.8335ZM8.33317 9.16683C10.1748 9.16683 11.6665 7.67516 11.6665 5.8335C11.6665 3.99183 10.1748 2.50016 8.33317 2.50016C6.4915 2.50016 4.99984 3.99183 4.99984 5.8335C4.99984 7.67516 6.4915 9.16683 8.33317 9.16683ZM15.2365 12.2527C16.4076 12.7801 17.4015 13.6345 18.0987 14.7133C18.7958 15.792 19.1666 17.0491 19.1665 18.3335H17.4998C17.5 17.3701 17.222 16.4272 16.6991 15.6181C16.1762 14.8091 15.4307 14.1682 14.5523 13.7727L15.2357 12.2527H15.2365ZM14.6632 2.84433C15.5028 3.19041 16.2206 3.77811 16.7257 4.53285C17.2307 5.2876 17.5002 6.17536 17.4998 7.0835C17.5002 8.22711 17.0729 9.32952 16.302 10.1742C15.531 11.0189 14.4721 11.5447 13.3332 11.6485V9.971C13.9506 9.88256 14.5235 9.59851 14.9676 9.16054C15.4117 8.72258 15.7037 8.15376 15.8008 7.53761C15.8979 6.92145 15.7948 6.29041 15.5068 5.73712C15.2188 5.18384 14.761 4.73745 14.2007 4.4635L14.6632 2.84433Z"
        fill="#0F1324"
        fillOpacity="0.6"
      />
    </svg>
  )
}
