export const VehiclesIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M15.8332 16.6668H4.1665V17.5002C4.1665 17.7212 4.07871 17.9331 3.92243 18.0894C3.76615 18.2457 3.55418 18.3335 3.33317 18.3335H2.49984C2.27882 18.3335 2.06686 18.2457 1.91058 18.0894C1.7543 17.9331 1.6665 17.7212 1.6665 17.5002V9.16683L3.73317 4.3435C3.86177 4.0435 4.07563 3.78786 4.34822 3.60831C4.6208 3.42876 4.9401 3.33321 5.2665 3.3335H14.7332C15.0593 3.33353 15.3782 3.42924 15.6505 3.60877C15.9227 3.7883 16.1363 4.04376 16.2648 4.3435L18.3332 9.16683V17.5002C18.3332 17.7212 18.2454 17.9331 18.0891 18.0894C17.9328 18.2457 17.7209 18.3335 17.4998 18.3335H16.6665C16.4455 18.3335 16.2335 18.2457 16.0772 18.0894C15.921 17.9331 15.8332 17.7212 15.8332 17.5002V16.6668ZM16.6665 10.8335H3.33317V15.0002H16.6665V10.8335ZM3.47984 9.16683H16.5198L14.734 5.00016H5.2665L3.48067 9.16683H3.47984ZM5.4165 14.1668C5.08498 14.1668 4.76704 14.0351 4.53262 13.8007C4.2982 13.5663 4.1665 13.2484 4.1665 12.9168C4.1665 12.5853 4.2982 12.2674 4.53262 12.0329C4.76704 11.7985 5.08498 11.6668 5.4165 11.6668C5.74802 11.6668 6.06597 11.7985 6.30039 12.0329C6.53481 12.2674 6.6665 12.5853 6.6665 12.9168C6.6665 13.2484 6.53481 13.5663 6.30039 13.8007C6.06597 14.0351 5.74802 14.1668 5.4165 14.1668ZM14.5832 14.1668C14.2517 14.1668 13.9337 14.0351 13.6993 13.8007C13.4649 13.5663 13.3332 13.2484 13.3332 12.9168C13.3332 12.5853 13.4649 12.2674 13.6993 12.0329C13.9337 11.7985 14.2517 11.6668 14.5832 11.6668C14.9147 11.6668 15.2326 11.7985 15.4671 12.0329C15.7015 12.2674 15.8332 12.5853 15.8332 12.9168C15.8332 13.2484 15.7015 13.5663 15.4671 13.8007C15.2326 14.0351 14.9147 14.1668 14.5832 14.1668Z"
        fill="#0F1324"
        fillOpacity="0.6"
      />
    </svg>
  )
}
