import React, { useEffect, useState } from "react"
import { useHistory, Link, useLocation } from "react-router-dom"
import { MoreOutlined, LogoutOutlined } from "@ant-design/icons"

import { Dropdown, Layout, Menu, message } from "antd"
import routes from "../constants/router"
import SubMenu from "antd/es/menu/SubMenu"
import IconFinder from "../constants/icons"
import { LayoutIcon } from "../constants/icons/LayoutIcon"
import UserCard from "../components/User/UserCard"
import { requests } from "../utils/requests"
const { Sider, Content } = Layout
function MainLayout({ children }) {
  const history = useHistory()
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    // requests.Auth.me()
  }, [])

  const items = [
    {
      label: (
        <div onClick={() => confirm()}>
          <LogoutOutlined />
          {`  `}Log out
        </div>
      ),
      key: "0",
    },
  ]
  const confirm = () => {
    localStorage.removeItem("token")
    history.push("/login")
    message.success("Log out")
  }
  const cancel = (e) => {
    console.log(e)
    // message.error("Click on No")
  }
  function menuGenerator(routes) {
    var renderMenu = routes
    return renderMenu?.map((e, i) => {
      if (e?.children && e?.children.length && !e?.noSub) {
        return (
          <SubMenu
            key={e.meta.title}
            icon={IconFinder(e?.meta?.icon)}
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {e?.meta?.title}
              </div>
            }
          >
            {e?.children.map((item, key) => (
              <Menu.Item key={item.path}>
                <Link to={item.path}>{item.meta.title}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        )
      } else {
        return (
          <Menu.Item key={e?.path} icon={IconFinder(e?.meta?.icon)}>
            <Link to={e?.path}>{e?.meta?.title}</Link>
          </Menu.Item>
        )
      }
    })
  }

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Sider
        trigger={null}
        style={{
          backgroundColor: "#F7F7F8",
          borderRight: "1px solid #DEE0E3",
        }}
        collapsible
        collapsed={collapsed}
      >
        <div className="flex justify-between flex-col h-full">
          <div>
            <div className="logo">
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <img src="/logo.svg" alt="logo" width={25} height={25} />
                {!collapsed && <p>Voltiva</p>}
              </div>
              {!collapsed && (
                <div
                //  onClick={() => setCollapsed(!collapsed)}
                >
                  <LayoutIcon />
                </div>
              )}
            </div>
            <Menu
              theme="light"
              mode="inline"
              style={{
                overflowY: "auto",
                maxHeight: "90vh",
                backgroundColor: "#F7F7F8",
                border: "none",
              }}
              //   onOpenChange={onOpenChange}
              //   openKeys={openKeys}
              //   defaultSelectedKeys={[location.pathname.split("/")[1]]}
              selectedKeys={[location.pathname]}
            >
              {menuGenerator(routes)}
            </Menu>
          </div>

          <div className="m-4 flex justify-between">
            <UserCard
              avatar="/images/Avatar.svg"
              phone={"+998 (97) 721-13-31"}
              name="Azizbek"
            />
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <MoreOutlined size={16} />
            </Dropdown>
          </div>
        </div>
      </Sider>
      <Layout style={{ background: "#fff" }}>
        {/* <Header
          style={{
            padding: 0,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
              marginRight: 30,
            }}
          />
          <Popconfirm
            title="Chiqish amali"
            description="Bu vazifaga ishonchingiz komilmi?"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              icon={<LogoutOutlined />}
              style={{
                fontSize: "16px",
                height: 34,
              }}
            >
              Chiqish
            </Button>
          </Popconfirm>
        </Header> */}
        <Content
          style={{
            minHeight: 280,
            overflowY: "auto",
            background: "#fff",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default MainLayout
