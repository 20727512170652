export const StationsIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M16.6665 9.16667H15.8332V5.83333H16.6665V3.33333H18.3332V5.83333H19.1665V9.16667H18.3332V15C18.3332 15.663 18.0698 16.2989 17.6009 16.7678C17.1321 17.2366 16.4962 17.5 15.8332 17.5C15.1701 17.5 14.5342 17.2366 14.0654 16.7678C13.5966 16.2989 13.3332 15.663 13.3332 15V11.6667H11.6665V15.8333H12.4998V17.5H1.6665V15.8333H2.49984V3.33333C2.49984 3.11232 2.58763 2.90036 2.74391 2.74408C2.9002 2.5878 3.11216 2.5 3.33317 2.5H10.8332C11.0542 2.5 11.2661 2.5878 11.4224 2.74408C11.5787 2.90036 11.6665 3.11232 11.6665 3.33333V10H13.3332C13.7752 10 14.1991 10.1756 14.5117 10.4882C14.8242 10.8007 14.9998 11.2246 14.9998 11.6667V15C14.9998 15.221 15.0876 15.433 15.2439 15.5893C15.4002 15.7455 15.6122 15.8333 15.8332 15.8333C16.0542 15.8333 16.2661 15.7455 16.4224 15.5893C16.5787 15.433 16.6665 15.221 16.6665 15V9.16667ZM9.99984 15.8333V4.16667H4.1665V15.8333H9.99984ZM7.49984 9.16667H9.99984L6.6665 14.1667V10.8333H4.1665L7.49984 5.83333V9.16667Z"
        fill="#0F1324"
        fillOpacity="0.6"
      />
    </svg>
  )
}
