export const PartnersIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.5002 15.8333H19.1668V17.5H0.833496V15.8333H2.50016V3.33333C2.50016 3.11232 2.58796 2.90036 2.74424 2.74408C2.90052 2.5878 3.11248 2.5 3.3335 2.5H11.6668C11.8878 2.5 12.0998 2.5878 12.2561 2.74408C12.4124 2.90036 12.5002 3.11232 12.5002 3.33333V15.8333H15.8335V9.16667H14.1668V7.5H16.6668C16.8878 7.5 17.0998 7.5878 17.2561 7.74408C17.4124 7.90036 17.5002 8.11232 17.5002 8.33333V15.8333ZM4.16683 4.16667V15.8333H10.8335V4.16667H4.16683ZM5.8335 9.16667H9.16683V10.8333H5.8335V9.16667ZM5.8335 5.83333H9.16683V7.5H5.8335V5.83333Z"
        fill="#0F1324"
        fillOpacity="0.6"
      />
    </svg>
  )
}
