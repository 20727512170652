export const LiveMapIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M1.6665 4.1665L7.49984 1.6665L12.4998 4.1665L17.7523 1.91567C17.8157 1.88849 17.8849 1.87748 17.9536 1.88362C18.0223 1.88977 18.0884 1.91288 18.146 1.95087C18.2036 1.98887 18.2508 2.04057 18.2835 2.10134C18.3162 2.1621 18.3332 2.23002 18.3332 2.299V15.8332L12.4998 18.3332L7.49984 15.8332L2.24734 18.084C2.18393 18.1112 2.11477 18.1222 2.04606 18.1161C1.97735 18.1099 1.91124 18.0868 1.85366 18.0488C1.79608 18.0108 1.74884 17.9591 1.71618 17.8983C1.68351 17.8376 1.66644 17.7697 1.6665 17.7007V4.1665ZM12.4998 16.4698V5.97984L12.4457 6.00317L7.49984 3.52984V14.0198L7.554 13.9965L12.4998 16.4698Z"
        fill="#0F1324"
        fillOpacity="0.6"
      />
    </svg>
  )
}
