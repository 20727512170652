export const ModeratorsIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10.0002 11.6668V13.3335C8.67408 13.3335 7.40231 13.8603 6.46463 14.798C5.52695 15.7356 5.00016 17.0074 5.00016 18.3335H3.3335C3.3335 16.5654 4.03588 14.8697 5.28612 13.6195C6.53636 12.3692 8.23205 11.6668 10.0002 11.6668ZM10.0002 10.8335C7.23766 10.8335 5.00016 8.596 5.00016 5.8335C5.00016 3.071 7.23766 0.833496 10.0002 0.833496C12.7627 0.833496 15.0002 3.071 15.0002 5.8335C15.0002 8.596 12.7627 10.8335 10.0002 10.8335ZM10.0002 9.16683C11.8418 9.16683 13.3335 7.67516 13.3335 5.8335C13.3335 3.99183 11.8418 2.50016 10.0002 2.50016C8.1585 2.50016 6.66683 3.99183 6.66683 5.8335C6.66683 7.67516 8.1585 9.16683 10.0002 9.16683ZM17.5002 14.1668H18.3335V18.3335H11.6668V14.1668H12.5002V13.3335C12.5002 12.6705 12.7636 12.0346 13.2324 11.5657C13.7012 11.0969 14.3371 10.8335 15.0002 10.8335C15.6632 10.8335 16.2991 11.0969 16.7679 11.5657C17.2368 12.0346 17.5002 12.6705 17.5002 13.3335V14.1668ZM15.8335 14.1668V13.3335C15.8335 13.1125 15.7457 12.9005 15.5894 12.7442C15.4331 12.588 15.2212 12.5002 15.0002 12.5002C14.7792 12.5002 14.5672 12.588 14.4109 12.7442C14.2546 12.9005 14.1668 13.1125 14.1668 13.3335V14.1668H15.8335Z"
        fill="#0F1324"
        fillOpacity="0.6"
      />
    </svg>
  )
}
