import React, { Suspense, useEffect, useState } from "react"
import { Redirect, Route, Switch, withRouter } from "react-router-dom"
import routes from "../constants/router"
import { Spin } from "antd"
import MainLayout from "../layout/MainLayout"
// import { useSelector } from "react-redux"

const Routes = () => {
  const [publicRouteList, setPublicRouteList] = useState([])
  const [isGenerated, setIsGenerated] = useState(false)

  useEffect(() => {
    generatedRoutes(routes)
  }, [])

  //   useEffect(() => {
  //     nprogress.done()
  //     return () => nprogress.start()
  //   })

  const token = localStorage.getItem("token")
  function generatedRoutes(rout) {
    rout.forEach((e, i) => {
      if (e.children && e.children.length) {
        setPublicRouteList((old) => [
          ...old,
          <AppRoute key={e} exact path={e.path} component={e.component} />,
        ])
        generatedRoutes(e.children)
      } else {
        setPublicRouteList((old) => [
          ...old,
          <AppRoute key={e} exact path={e.path} component={e.component} />,
        ])
        setIsGenerated(true)
      }
    })
  }

  const AppRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<></>}>
          <Component {...props} />
        </Suspense>
      )}
    />
  )

  return (
    <>
      {isGenerated ? (
        <Switch>
          {token ? (
            <MainLayout>{publicRouteList}</MainLayout>
          ) : (
            <Redirect from="*" to="/login" />
          )}
        </Switch>
      ) : undefined}
    </>
  )
}

export default withRouter(Routes)
