import React from "react"
import { formatPhoneNumber } from "../../utils"

function UserCard({
  avatar = "/images/Avatar.png",
  name = "",
  phone,
  is_verified,
  is_blocked,
}) {
  return (
    <div className="flex gap-2 ">
      {is_blocked ? (
        <img
          src={"/images/block-user.png"}
          alt={""}
          className="object-contain w-10 h-10"
        />
      ) : (
        <div className="relative">
          <img src={avatar} alt={""} className="object-contain w-10 h-10" />
          <div
            className={`absolute right-0 bottom-0 w-3 h-3 rounded-full  ${
              is_verified
                ? "bg-[#26BD6C] border-2 border-[white]"
                : is_verified === false &&
                  "bg-[#E6483D] border-2 border-[white]"
            }`}
          />
        </div>
      )}
      <div>
        <h3 className="text-sm font-medium">{name}</h3>
        <p className="text-[#0F132499] text-xs">{formatPhoneNumber(phone)}</p>
      </div>
    </div>
  )
}

export default UserCard
